<template>
  <div>
    <Header :title="title" :back="true"/>
    <div class="add">
      <div class="add-title theme-color">为账户安全考虑，您只能提现到本人同名银行账户。</div>
      <div class="box-items">
        <div class="items-form">
          <el-input
            class="form-items"
            @focus="tips=''"
            placeholder="请输入收款账户姓名拼音"
            v-model="name"
            prefix-icon="el-icon-user"
            clearable>
          </el-input>
        </div>
        <div class="items-form">
          <el-select v-model="bankType" style="width:100%" placeholder="请选择银行账户类别" @focus="tips=''" @change="changeBankType">
            <el-option label="中国香港银行" value="hongkong"></el-option>
            <el-option label="中国内地银行" value="mainland"></el-option>
            <el-option label="其他国家/地区银行" value="other"></el-option>
          </el-select>
        </div>
        <div class="items-form " v-if="bankType=='hongkong'">
          <el-select v-model="hKcode"  popper-class="lineh" @focus="tips=''" filterable style="width:100%" placeholder="请搜索选择收款银行">
            <el-option
              v-for="item in HKoptions"
              :key="item.bankcode"
              :label="item.name"
              :value="item.bankcode+'-'+item.name">
              <div class="theme-color">{{item.enname}}</div>
              <div style="font-size:.9rem;color:#464646">{{item.name}},代码{{item.bankcode}}</div>
            </el-option>
          </el-select>
        </div>
        <div class="items-form" v-if="bankType=='other'">
          <el-input
            class="form-items"
            @focus="tips=''"
            placeholder="请输入国家/地区（例：加拿大Canada）"
            v-model="address"
            prefix-icon="el-icon-map-location"
            clearable>
          </el-input>
        </div>
        <div class="items-form" v-if="bankType=='mainland'||bankType=='other'">
          <el-input
            class="form-items"
            @focus="tips=''"
            :placeholder="pla"
            v-model="bank"
            prefix-icon="el-icon-office-building"
            clearable>
          </el-input>
        </div>
        <div class="items-form" v-if="bankType=='mainland'||bankType=='other'">
          <el-input
            class="form-items"
            @focus="tips=''"
            placeholder="请输入SWIFT码（可联系银行获取）"
            v-model="swift"
            prefix-icon="el-icon-connection"
            clearable>
          </el-input>
        </div>
        
        <div class="items-form" v-if="bankType">
          <el-input
            class="form-items"
            @focus="tips=''"
            placeholder="请输入银行卡号"
            v-model="number"
            prefix-icon="el-icon-bank-card"
            clearable>
          </el-input>
        </div>
        <div v-if="tips" class="error-tips"><i class="el-icon-error"></i>{{tips}}</div>
        <div class="items-form"><button class="wid-btn" style="width:96%" @click="handerConfirm">确认添加</button></div>

      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return{
      title:'添加收款账户',
      tips:'',
      name:'',//姓名
      bankType:'',//类别
      hKcode:'',//code和名称银行
      bank:'',//银行
      number:'',//银行卡号
      swift:'',
      address:'',
      HKoptions:[],
      pla:'',
      // id:'',
      // resList:null
    }

  },
  created(){
    // this.id=this.$route.query.id;
    // if(this.id){
    //   this.title= "编辑收款账户"
    //   this.getDetail()
    // }
  },
  methods:{
    // getDetail(){
    //    this.$store.dispatch('transation/getBanks').then(res => {
    //     if(res&&res.code=='success'){
    //       this.resList = res.result.filter(item=>item.id==this.id)
    //       console.log(this.resList)

    //     }
    //   })
    // },
    getHKBanksData(){
      this.$store.dispatch('transation/HKBanks',{params:{type:'hongkong'}}).then(res => {
        if(res&&res.code=='success'){
          this.HKoptions = res.result
        }
      })
    },
    changeBankType(value){
      if(value=='hongkong'){
        this.getHKBanksData()
      }
      if(value=='mainland'){
       this.pla="请输入收款银行（推荐工行，避免中行）"
      }
      if(value=='other'){
        this.pla="请输入收款银行英文名称"
      }
    },
    handerConfirm(){
      if(!this.name){
        this.tips="请输入收款账户姓名拼音"
        return false
      }
      if(!this.bankType){
        this.tips="请输入银行账户类别"
        return false
      }
      if(this.bankType=="hongkong"){
        if(!this.hKcode){
          this.tips="请选择收款银行"
          return false
        }
        if(!this.number){
          this.tips="请输入银行卡号"
          return false
        }
      }
      if(this.bankType=="mainland"){
        if(!this.bank){
          this.tips="请输入收款银行"
          return false
        }
        if(!this.swift){
          this.tips="请输入SWIFT码"
          return false
        }
        if(!this.number){
          this.tips="请输入银行卡号"
          return false
        }
      }
      if(this.bankType=="other"){
        if(!this.address){
          this.tips="请输入国家/地区"
          return false
        }
        if(!this.bank){
          this.tips="请输入收款银行英文名称"
          return false
        }
        if(!this.swift){
          this.tips="请输入SWIFT码"
          return false
        }
        if(!this.number){
          this.tips="请输入银行卡号"
          return false
        }
      }
      let params={
        payee:this.name,
        type:this.bankType,
        number:this.name
      }
      if(this.bankType=="hongkong"){
        params.bankcode = this.hKcode.slice(0,this.hKcode.indexOf('-'))
        params.bank = this.hKcode.slice(this.hKcode.indexOf('-')+1)
      }
      if(this.bankType=="other"){
        params.address = this.address
      }
      if(this.bankType!="hongkong"){
        params.bank = this.bank
        params.swift = this.swift
      }
      this.$store.dispatch('transation/addBank',params).then(res => {

        if(res&&res.code=='success'){
          this.$router.push({path:'bank'})
        }else{
          this.tips=res.msg
        }
      })
    }
  }
  
}
</script>
<style lang="scss" scoped>
.add{
  .add-title{
    margin:2.5%;
  }
  .items-form{
    margin-top:1rem;
  }
}
.lineh{
  .el-select-dropdown__item{
    height: 60px;
    line-height: 24px;
  }
}
</style>