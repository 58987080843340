var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", { attrs: { title: _vm.title, back: true } }),
      _c("div", { staticClass: "add" }, [
        _c("div", { staticClass: "add-title theme-color" }, [
          _vm._v("为账户安全考虑，您只能提现到本人同名银行账户。"),
        ]),
        _c("div", { staticClass: "box-items" }, [
          _c(
            "div",
            { staticClass: "items-form" },
            [
              _c("el-input", {
                staticClass: "form-items",
                attrs: {
                  placeholder: "请输入收款账户姓名拼音",
                  "prefix-icon": "el-icon-user",
                  clearable: "",
                },
                on: {
                  focus: function ($event) {
                    _vm.tips = ""
                  },
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "items-form" },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择银行账户类别" },
                  on: {
                    focus: function ($event) {
                      _vm.tips = ""
                    },
                    change: _vm.changeBankType,
                  },
                  model: {
                    value: _vm.bankType,
                    callback: function ($$v) {
                      _vm.bankType = $$v
                    },
                    expression: "bankType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "中国香港银行", value: "hongkong" },
                  }),
                  _c("el-option", {
                    attrs: { label: "中国内地银行", value: "mainland" },
                  }),
                  _c("el-option", {
                    attrs: { label: "其他国家/地区银行", value: "other" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.bankType == "hongkong"
            ? _c(
                "div",
                { staticClass: "items-form" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "popper-class": "lineh",
                        filterable: "",
                        placeholder: "请搜索选择收款银行",
                      },
                      on: {
                        focus: function ($event) {
                          _vm.tips = ""
                        },
                      },
                      model: {
                        value: _vm.hKcode,
                        callback: function ($$v) {
                          _vm.hKcode = $$v
                        },
                        expression: "hKcode",
                      },
                    },
                    _vm._l(_vm.HKoptions, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.bankcode,
                          attrs: {
                            label: item.name,
                            value: item.bankcode + "-" + item.name,
                          },
                        },
                        [
                          _c("div", { staticClass: "theme-color" }, [
                            _vm._v(_vm._s(item.enname)),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": ".9rem",
                                color: "#464646",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(item.name) +
                                  ",代码" +
                                  _vm._s(item.bankcode)
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.bankType == "other"
            ? _c(
                "div",
                { staticClass: "items-form" },
                [
                  _c("el-input", {
                    staticClass: "form-items",
                    attrs: {
                      placeholder: "请输入国家/地区（例：加拿大Canada）",
                      "prefix-icon": "el-icon-map-location",
                      clearable: "",
                    },
                    on: {
                      focus: function ($event) {
                        _vm.tips = ""
                      },
                    },
                    model: {
                      value: _vm.address,
                      callback: function ($$v) {
                        _vm.address = $$v
                      },
                      expression: "address",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.bankType == "mainland" || _vm.bankType == "other"
            ? _c(
                "div",
                { staticClass: "items-form" },
                [
                  _c("el-input", {
                    staticClass: "form-items",
                    attrs: {
                      placeholder: _vm.pla,
                      "prefix-icon": "el-icon-office-building",
                      clearable: "",
                    },
                    on: {
                      focus: function ($event) {
                        _vm.tips = ""
                      },
                    },
                    model: {
                      value: _vm.bank,
                      callback: function ($$v) {
                        _vm.bank = $$v
                      },
                      expression: "bank",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.bankType == "mainland" || _vm.bankType == "other"
            ? _c(
                "div",
                { staticClass: "items-form" },
                [
                  _c("el-input", {
                    staticClass: "form-items",
                    attrs: {
                      placeholder: "请输入SWIFT码（可联系银行获取）",
                      "prefix-icon": "el-icon-connection",
                      clearable: "",
                    },
                    on: {
                      focus: function ($event) {
                        _vm.tips = ""
                      },
                    },
                    model: {
                      value: _vm.swift,
                      callback: function ($$v) {
                        _vm.swift = $$v
                      },
                      expression: "swift",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.bankType
            ? _c(
                "div",
                { staticClass: "items-form" },
                [
                  _c("el-input", {
                    staticClass: "form-items",
                    attrs: {
                      placeholder: "请输入银行卡号",
                      "prefix-icon": "el-icon-bank-card",
                      clearable: "",
                    },
                    on: {
                      focus: function ($event) {
                        _vm.tips = ""
                      },
                    },
                    model: {
                      value: _vm.number,
                      callback: function ($$v) {
                        _vm.number = $$v
                      },
                      expression: "number",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.tips
            ? _c("div", { staticClass: "error-tips" }, [
                _c("i", { staticClass: "el-icon-error" }),
                _vm._v(_vm._s(_vm.tips)),
              ])
            : _vm._e(),
          _c("div", { staticClass: "items-form" }, [
            _c(
              "button",
              {
                staticClass: "wid-btn",
                staticStyle: { width: "96%" },
                on: { click: _vm.handerConfirm },
              },
              [_vm._v("确认添加")]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }